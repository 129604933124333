const colors = {
  blue: "#1C4DAC",
  kindaBlue: "#3072F1",
  darkBlue: "#16336C",
  lightBlue: "#39A0ED",
  black: "#0F0F0F",
  white: "#FBFBFB",
};

export default colors;
