import React, { useEffect, useState, forwardRef } from "react";
import colors from "../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faDollarSign,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import services from "../docs/services";
import { animated, useSpring } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

const Services = forwardRef((props, ref) => {
  const [cardContainerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} style={{ color: colors.white }}>
      <div style={{ margin: "0px 24px" }}>
        <h1 style={{ fontSize: 40, textAlign: "center" }}>
          Preços simples e transansparentes
        </h1>
        <h3
          style={{
            fontSize: 20,
            textAlign: isMobile ? "start" : "center",
            fontWeight: "normal",
            marginTop: 10,
          }}
        >
          Por que escolher nossos serviços? Além de preços justos, oferecemos
          suporte personalizado, prazos rápidos e qualidade garantida.
        </h3>
      </div>
      <div
        ref={cardContainerRef}
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          marginTop: isMobile ? 0 : 32,
          justifyContent: "center",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <Card {...services.simple} inView={inView} index={0} />
        <Card {...services.complex} inView={inView} index={1} />
      </div>
    </div>
  );
});

const Card = ({
  index,
  inView,
  title,
  subTitle,
  cost,
  content,
  deliverStr,
  monthlyCost,
}) => {
  const [delayedInView, setDelayedInView] = useState(false);
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setDelayedInView(true);
      }, 200 * (1 - index));
    }
  }, [inView, index]);

  const cardSpring = useSpring({
    from: { transform: "translate3d(-100vw, 0, 0)" },
    to: {
      transform: delayedInView
        ? "translate3d(0, 0, 0)"
        : "translate3d(-100vw, 0, 0)",
    },
    config: { duration: 300, tension: 100 },
  });
  return (
    <animated.div
      style={{
        backgroundColor: colors.kindaBlue,
        color: colors.white,
        padding: 24,
        borderRadius: 32,
        width: isMobile ? "80vw" : 360,
        boxShadow: "0 1px 4px rgba(0, 0, 0, 0.3)",
        marginLeft: isMobile ? 0 : 24,
        marginTop: isMobile ? 32 : 0,
        ...cardSpring,
      }}
    >
      <h2 style={{ fontSize: 32 }}>{title}</h2>
      <span style={{ display: "block", marginTop: 12 }}>{subTitle}</span>

      <div
        style={{
          display: "flex",
          alignItems: "space-between",
          marginTop: 24,
          marginLeft: 12,
        }}
      >
        <b style={{ fontSize: 24 }}>R$</b>
        <h1 style={{ fontSize: 48, marginLeft: 12 }}>{cost}</h1>
      </div>

      <button
        style={{
          backgroundColor: colors.darkBlue,
          color: colors.white,
          padding: "8px 0px",
          width: "100%",
          borderRadius: 4,
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 16,
        }}
      >
        Entrar em contato
      </button>

      <div style={{ marginTop: 24 }}>
        {content.map((text) => (
          <CardContent icon={faCheck} text={text} hasLine />
        ))}

        <CardContent icon={faTruck} text={deliverStr} hasLine />
        <CardContent icon={faDollarSign} text={monthlyCost} noMargin />
      </div>
    </animated.div>
  );
};

const CircleIcon = ({ icon }) => {
  return (
    <div
      style={{
        padding: 6,
        borderRadius: 32,
        backgroundColor: colors.darkBlue,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        color={colors.white}
        width={16}
        height={16}
      />
    </div>
  );
};

const CardContent = ({ icon, text, hasLine = false, noMargin = false }) => {
  return (
    <div style={{ marginBottom: hasLine ? 0 : noMargin ? 0 : 14 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CircleIcon icon={icon} />
        <span style={{ marginLeft: 8 }}>{text}</span>
      </div>

      {hasLine && (
        <div
          style={{
            height: 1,
            width: "100%",
            margin: "14px 0px",
            background: colors.darkBlue,
            borderRadius: 2,
          }}
        />
      )}
    </div>
  );
};

export default Services;
