import colors from "../colors";

import { animated, useSpring } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

export default function Hero() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(-200%, 0, 0)" },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translate3d(0, 0, 0)" : "translate3d(-200%, 0, 0)",
    },
    config: {
      duration: 300,
      ension: 200,
      friction: 30,
      mass: 1,
      precision: 0.01,
      bounce: 0,
    },
  });

  return (
    <main
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: isMobile ? "center" : "flex-start",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero.png)`,
        height: isMobile ? "auto" : "80vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: isMobile ? "40px 0px" : 0,
      }}
    >
      <InfoContainer containerSpring={containerSpring} />
    </main>
  );
}

const InfoContainer = ({ containerSpring }) => {
  return (
    <animated.div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        padding: isMobile ? "20px 0px" : 20,
        width: isMobile ? "90vw" : 450,
        color: colors.black,
        marginLeft: isMobile ? 0 : 64,
        borderRadius: 6,
        ...containerSpring,
      }}
    >
      <h1 style={{ fontSize: 32, textAlign: "center", color: colors.blue }}>
        (Site em producao), LEIA VV
      </h1>
      <div style={{ fontSize: 20 }}>
        <ul style={{ fontWeight: "120%" }}>
          <li style={{ marginTop: 24 }}>
            Feito utilizando react e as animacoes usando a lib react-spring. SO:
            linux mas me viro em qualquer um
          </li>
          <li style={{ marginTop: 24 }}>
            Tenho um projeto de mais ou menos 40 telas desenhadas por mim e pelo
            meu primo para ser feito a longo prazo, uma rede social vegana
            (posso mostrar por chamada)
          </li>
          <li style={{ marginTop: 24 }}>
            Tenho experiencia de contato de alguns dos freelas que fiz e aprendo
            linguas como hobby (recente) atualmente falo bem ingles e estou
            entre iniciante e intermediario no frances (aprendo sozinho, entao
            nao sei metricar)
          </li>
        </ul>
      </div>

      {/* <SeeMoreButton /> */}
    </animated.div>
  );
};

const SeeMoreButton = () => {
  return (
    <button
      style={{
        backgroundColor: colors.blue,
        color: colors.white,
        padding: "12px 24px",
        borderRadius: 6,
        marginTop: 20,
        lineHeight: 1.25,
      }}
    >
      <h1 style={{ fontSize: 28 }}>Saber mais</h1>
    </button>
  );
};
