import "./App.css";
import { useRef } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import OurProjects from "./components/OurProjects";
import Services from "./components/Services";
import Footer from "./components/Footer";

function App() {
  const ourProjectsRef = useRef(null);
  const servicesRef = useRef(null);

  return (
    <div>
      <Header ourProjectsRef={ourProjectsRef} servicesRef={servicesRef} />
      <Hero />
      <OurProjects ref={ourProjectsRef} />
      <Services ref={servicesRef} />
      <Footer />
    </div>
  );
}

export default App;
