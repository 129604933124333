const projects = [
    {name: "Advocacia", fileName: "adv.png"},
    {name: "Arquitetura", fileName: "architeture.png"},
    {name: "Limpador de Piscinas", fileName: "askPool.png"},
    {name: "Padaria", fileName: "barber.png"},
    {name: "Blindadora", fileName: "carBlindado.png"},
    {name: "Barbearia", fileName: "barber.png"},
    // {name: "GTA san andreas online", fileName: "il.png"},
    // {name: "Aprender Linguas com Manga", fileName: "llwm.png"},
    {name: "Montador de Pergolas", fileName: "pergolaWood.png"},
    // {name: "Pronuncie Certo", fileName: "pronounceRight.png"},
    {name: "Tatuador", fileName: "tattoo.png"},
]

export default projects;