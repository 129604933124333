import { forwardRef, useEffect, useState } from "react";
import colors from "../colors";
import projects from "../docs/projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import { animated, useSpring } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

const OurProjects = forwardRef((props, ref) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [delaySelectedIndex, setDelaySelectedIndex] = useState(-1);

  useEffect(() => {
    if (selectedImageIndex == -1) {
      setTimeout(() => {
        setDelaySelectedIndex(-1);
      }, 600);
    } else {
      setDelaySelectedIndex(selectedImageIndex);
    }
  }, [selectedImageIndex]);

  const [textRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [rowRef, inViewRow] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const textSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(-200%, 0, 0)" },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translate3d(0, 0, 0)" : "translate3d(-200%, 0, 0)",
    },
    config: { duration: 300 },
  });

  const rowSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(-200%, 0, 0)" },
    to: {
      opacity: inViewRow ? 1 : 0,
      transform: inViewRow
        ? "translate3d(0, 0, 0)"
        : "translate3d(-200%, 0, 0)",
    },
    config: { duration: 900 },
  });

  return (
    <div ref={ref}>
      {delaySelectedIndex != -1 && (
        <ImageModal
          setSelectedImageIndex={setSelectedImageIndex}
          selectedImageIndex={selectedImageIndex}
          delaySelectedIndex={delaySelectedIndex}
        />
      )}
      <main
        ref={textRef}
        style={{
          background: `linear-gradient(to bottom, ${colors.darkBlue} 0%, ${colors.blue} 100%)`,
          padding: 40,
        }}
      >
        <div ref={rowRef}>
          <ProjectsRow
            title="Sites parceiros:"
            textSpring={textSpring}
            rowSpring={rowSpring}
            selectedImageIndex={selectedImageIndex}
            setSelectedImageIndex={setSelectedImageIndex}
          />
        </div>
      </main>
    </div>
  );
});

const ProjectsRow = ({
  title,
  textSpring,
  rowSpring,
  selectedImageIndex,
  setSelectedImageIndex,
}) => {
  const [translateX, setTranslateX] = useState(0);

  return (
    <div>
      <animated.h3
        style={{
          marginTop: 0,
          fontSize: 32,
          color: colors.white,
          marginBottom: 20,
          ...textSpring,
        }}
      >
        {title}
      </animated.h3>
      <animated.div
        style={{ overflowX: "hidden", marginLeft: -32, ...rowSpring }}
      >
        <div
          style={{
            display: "flex",
            transform: `translateX(${translateX}px)`,
            transition: "transform 600ms",
          }}
        >
          {projects.map((project, i) => (
            <div>
              <ProjectContainer
                project={project}
                selected={selectedImageIndex === i}
                setSelectedImageIndex={setSelectedImageIndex}
                index={i}
              />
            </div>
          ))}
        </div>

        <ProjectScrollFront
          setTranslateX={setTranslateX}
          translateX={translateX}
        />
        <ProjectScrollBack
          setTranslateX={setTranslateX}
          translateX={translateX}
        />
      </animated.div>
    </div>
  );
};

const ProjectScrollFront = ({ setTranslateX, translateX }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(
      translateX * -1 <= 700 * (projects.length - 1) - window.innerWidth + 600
    );
  }, [translateX]);

  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: isMobile ? 80 : 300,
        background: `linear-gradient(to left, #16336C ${
          isMobile ? 60 : 30
        }% , transparent 100%)`,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        cursor: "pointer",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 300ms",
      }}
      onClick={() => {
        if (isVisible) setTranslateX((prev) => prev - 750);
      }}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        size={isMobile ? "3x" : "6x"}
        color={colors.white}
        style={{ marginRight: isMobile ? 10 : 20 }}
      />
    </div>
  );
};

const ProjectScrollBack = ({ setTranslateX, translateX }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(translateX * -1 > 0);
  }, [translateX]);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: isMobile ? 80 : 300,
        background: `linear-gradient(to right, #16336C ${
          isMobile ? 60 : 30
        }%, transparent 100%)`,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 300ms",
      }}
      onClick={() => {
        if (isVisible) setTranslateX((prev) => prev + 750);
      }}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        size={isMobile ? "3x" : "6x"}
        color={colors.white}
        style={{ marginLeft: 20 }}
      />
    </div>
  );
};

const ProjectContainer = ({
  project,
  selected,
  setSelectedImageIndex,
  index,
}) => {
  const { name, fileName } = project;
  const [selectedWithDelay, setSelectedWithDelay] = useState(false);

  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        setSelectedWithDelay(true);
      }, 300);
    } else {
      setSelectedWithDelay(false);
    }
  }, [selected]);

  const imageSpring = useSpring({
    from: { height: "80vh" },
    to: {
      height: selected ? "0" : "80vh",
    },
    config: { duration: 300 },
  });

  const auxDivSpring = useSpring({
    from: { width: 700 },
    to: {
      width: selectedWithDelay ? 0 : 700,
    },
    config: { duration: 300 },
  });

  return (
    <div style={{ display: "flex" }}>
      <animated.div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/projects/${fileName})`,
          backgroundPosition: "top",
          width: "700px",
          backgroundSize: "cover",
          marginLeft: 32,
          cursor: "pointer",
          position: selected ? "fixed" : "static",
          ...imageSpring,
        }}
        onClick={() => {
          setSelectedImageIndex(index);
        }}
      />

      {selected && (
        <animated.div
          style={{ height: "80vh", marginLeft: 32, ...auxDivSpring }}
        />
      )}
    </div>
  );
};

const ImageModal = ({
  setSelectedImageIndex,
  selectedImageIndex,
  delaySelectedIndex,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  const imageSpring = useSpring({
    from: { height: "0vh" },
    to: {
      height: selectedImageIndex != -1 ? "90vh" : "0vh",
    },
    config: { duration: 300 },
  });

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        alignItems: "flex-end",
        justifyContent: "center",
        background: "rgba(0,0,0,.8)",
        zIndex: 10,
      }}
      onClick={() => setSelectedImageIndex(-1)}
    >
      <animated.div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          ...imageSpring,
          maxHeight: "95vh",
          marginBottom: "5vh",
          //   height: "80vh",
        }}
      >
        <img
          onClick={(e) => e.stopPropagation()}
          src={`images/projects/${projects[delaySelectedIndex].fileName}`}
          alt={"website showcase modal"}
          style={{ width: "80vw" }}
        />
      </animated.div>
    </div>
  );
};

export default OurProjects;
