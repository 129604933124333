import colors from "../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer
      style={{
        marginTop: 60,
        backgroundColor: colors.darkBlue,
        color: colors.white,
        padding: 24,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1 }}>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          icon={faTiktok}
          size="2x"
        />
        <FontAwesomeIcon
          icon={faInstagram}
          size="2x"
          style={{ marginLeft: 24, cursor: "pointer" }}
        />
        <FontAwesomeIcon
          icon={faYoutube}
          size="2x"
          style={{ marginLeft: 24, cursor: "pointer" }}
        />
      </div>
      <span style={{ flex: 1, textAlign: "center" }}>logo</span>
      <span style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        © 2024 Your Company Name. All Rights Reserved.
      </span>
    </footer>
  );
}
