import colors from "../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { isMobile } from "react-device-detect";

export default function Header({ ourProjectsRef, servicesRef }) {
  return (
    <header>
      <div
        style={{
          color: colors.white,
          display: "flex",
          justifyContent: "space-between",
          padding: isMobile ? "8px 12px" : "8px 24px",
          alignItems: "center",
          fontSize: 20,
          backgroundColor: colors.darkBlue,
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <div>LOGO</div>
        </div>
        {!isMobile && (
          <nav style={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <b
              onClick={() => {
                ourProjectsRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
              style={{ cursor: "pointer" }}
            >
              Projetos
            </b>
            <b
              style={{ paddingLeft: 25, cursor: "pointer" }}
              onClick={() => {
                servicesRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Serviços
            </b>
          </nav>
        )}
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <WhatsappButton />
        </div>
      </div>
    </header>
  );
}

const WhatsappButton = () => {
  return (
    <div
      style={{
        display: "flex",
        borderRadius: 8,
        backgroundColor: colors.lightBlue,
        color: colors.white,
        padding: "6px 12px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FontAwesomeIcon icon={faWhatsapp} color={colors.white} />
      <span style={{ marginLeft: 8 }}>Fale Conosco</span>
    </div>
  );
};
