const services = {
  simple: {
    title: "Site simples",
    subTitle:
      "página única, ideal  para apresentar informações  como: serviços ou portfólio.",
    cost: "600,00",
    content: [
      "Página única com conteúdo rolável",
      "Design unico e responsivel",
      "Bom SEO, para alcançar o topo do google",
      "Relatorios de visualizacoes periódico",
    ],
    deliverStr: "Prazo de uma semana",
    monthlyCost: "Custo de R$ 50,00 para manter online",
  },
  complex: {
    title: "Site complexo",
    subTitle:
      "Ideal para negócios que precisam de múltiplas páginas para informações claras e organizadas",
    cost: "1200,00",
    content: [
      "Diversas paginas com conteudo rolavel",
      "Design unico e responsivel",
      "Bom SEO, para alcançar o topo do google",
      "Relatorios de visualizacoes periódico",
    ],
    deliverStr: "Prazo de ate 3 semanas",
    monthlyCost: "Custo de R$ 100,00 para manter online",
  },
};

export default services;
